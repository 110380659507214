"use client"

import React, { useState, FormEvent, useEffect } from 'react'
import { Link as ScrollLink, Element, animateScroll as scroll } from 'react-scroll'
import { ChevronLeft, ChevronRight, Clock, ShieldCheck, Briefcase, Users, Phone, Mail, MapPin, X, CreditCard, Building, Home, Car, Stethoscope, GraduationCap, Landmark, Tractor, Plane, Leaf, Smartphone, ShoppingBag, CreditCard as CreditCardIcon, Calculator, FileText, CheckCircle, Cog, BarChart3, Facebook, Twitter, Instagram, Linkedin, ChevronUp, Loader2, Home as HomeIcon, Info, User, IndianRupee, Plus, Minus, Percent, Shield, Award, Zap } from 'lucide-react'

interface TeamMember {
  name: string
  role: string
  description: string
  image: string
}

interface BlogPost {
  title: string
  description: string
  url: string
  imageUrl: string
  date: string
}

export default function Component() {
  const [sliderIndex, setSliderIndex] = useState(0)
  const [loanAmount, setLoanAmount] = useState(100000)
  const [interestRate, setInterestRate] = useState(10)
  const [loanTerm, setLoanTerm] = useState(12)

  // Contact form state
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [loanType, setLoanType] = useState('')
  const [requiredLoan, setRequiredLoan] = useState('')
  const [message, setMessage] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<TeamMember>({ name: '', role: '', description: '' , image: ''})

  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([])
  const [isNewsLoading, setIsNewsLoading] = useState(true)
  const [newsError, setNewsError] = useState<string | null>(null)

  const slides = [
    { 
      desktop: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/09/Personal-Loan.jpg",
      mobile: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/09/Personal-Loan-1.jpg"
    },
    { 
      desktop: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/Business-Loan-2.jpg",
      mobile: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/Home-Loan-1.jpg"
    },
    { 
      desktop: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/Home-Loan-2.jpg",
      mobile: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/Business-loan-1.jpg"
    },
  ]

  const teamMembers: TeamMember[] = [
    { 
      name: "Shrenik Bapna", 
      role: "Founder",
      image: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/09/Shreniksir-01.png",
      description: "He is a seasoned professional with over 18 years of expertise in retail banking, insurance, and financial services. His career has spanned across leading roles in sales management."
    },
    { 
      name: "Aditya Khunteta", 
      role: "Co-Founder",
      image: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/09/4-01.png",
      description: "He is a chartered accountant in practice and has work experience of over 6 years. He works on project funding, loans, income tax and GST."
    },
    { 
      name: "Manish Mittal", 
      role: "Director",
      image: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/09/3-01.png",
      description: "He is a member of the institute of Chartered Accountants of India (ICAI). He has over 20+years of experience in resource mobilisation through debt and equity in Manufacturing, Services and Retail Sectors."
    },
    { 
      name: "Nikita Jain", 
      role: "Director",
      image: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/09/Nikita-mam-01.png",
      description: "She is a Chartered Accountant and an entrepreneur several times over with two decades of experience in the education, manufacturing, finance, real estate and pharma sectors."
    },
  ]

  const bankLogos = [
    { name: "State Bank of India", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/hdfc-1.png" },
    { name: "HDFC Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/axis-bank.png" },
    { name: "ICICI Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/icici-2.png" },
    { name: "Axis Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/idfc-bank.png" },
    { name: "Punjab National Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/BOB.jpg" },
    { name: "Bank of Baroda", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/indusiand-bank.png" },
    { name: "Canara Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/kotak-bank.png" },
    { name: "Union Bank of India", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/yesbank-loan.png" },
    { name: "Kotak Mahindra Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/pnb.png" },
    { name: "IndusInd Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/SBI.jpg" },
    { name: "Yes Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/bandhan-bank.png" },
    { name: "IDBI Bank", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/lendingkart-loan.png" },
    { name: "Bajaj Finserv", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/tata-capital-loan.png" },
    { name: "Tata Capital", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/incred-f.jpg" },
    { name: "Aditya Birla Finance", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/aditya-birla-loan.png" },
    { name: "Mahindra Finance", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/Paysense-f.jpg" },
    { name: "L&T Finance", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/LTF.png" },
    { name: "Muthoot Finance", logo: "https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/bajaj-loan.png" },
  ]

  const employeeTestimonials = [
    { name: "Priya Sharma", role: "Loan Officer", quote: "Working at Nanda Fincap has been an incredible journey. The supportive environment and growth opportunities are unmatched." },
    { name: "Rahul Verma", role: "Financial Analyst", quote: "I've learned so much in my time here. The company truly invests in its employees' professional development." },
    { name: "Anita Patel", role: "Customer Service Representative", quote: "The team at Nanda Fincap is like a family. We work together to provide the best service to our clients." },
  ]

  const calculateEMI = () => {
    const r = interestRate / 12 / 100
    const n = loanTerm
    const emi = (loanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1)
    return emi
  }

  const emi = calculateEMI()
  const totalAmount = emi * loanTerm
  const totalInterest = totalAmount - loanAmount

  const handleContactSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('loanType', loanType)
    formData.append('RequiredLoan', requiredLoan)
    formData.append('message', message)
    formData.append('access_key', 'ec7c545c-204a-46d0-873e-da69103db774')
    formData.append('subject', 'Loan Enquiry from Website')       
    formData.append('from_name', 'Nanda Fincap Pvt Ltd')

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formData
      })

      const data = await response.json()

      if (data.success) {
        setFormSubmitted(true)
        setName('')
        setEmail('')
        setPhone('')
        setLoanType('')
        setRequiredLoan('')
        setMessage('')
      } else {
        throw new Error('Form submission failed')
      }
    } catch (error) {
      console.error('Error:', error)
      alert('Failed to send message. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  const openModal = (member: TeamMember) => {
    setModalContent(member)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleCall = () => {
    window.location.href = 'tel:+917231981888'
  }

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
    })
  }

  const fetchNews = async () => {
    setIsNewsLoading(true)
    setNewsError(null)
    const corsProxy = 'https://cors-anywhere.herokuapp.com/'
    const url = `${corsProxy}https://blog.bankbazaar.com/category/loans/`

    try {
      const response = await fetch(url, {
        headers: {
          'Origin': 'https://your-app-domain.com' // Replace with your actual domain
        }
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const html = await response.text()
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')
      
      const articles = doc.querySelectorAll('article.post')
      const posts: BlogPost[] = Array.from(articles).slice(0, 6).map(article => {
        const titleElement = article.querySelector('.entry-title a')
        const imageElement = article.querySelector('img')
        const excerptElement = article.querySelector('.entry-excerpt')
        const dateElement = article.querySelector('.entry-date')

        return {
          title: titleElement ? titleElement.textContent || '' : '',
          url: titleElement ? titleElement.getAttribute('href') || '' : '',
          imageUrl: imageElement ? imageElement.getAttribute('src') || '' : '',
          description: excerptElement ? excerptElement.textContent || '' : '',
          date: dateElement ? dateElement.textContent || '' : '',
        }
      })

      setBlogPosts(posts)
    } catch (error) {
      console.error('Error fetching news:', error)
      setNewsError('Failed to fetch latest news. Using fallback content.')
      // Fallback content
      setBlogPosts([
        {
          title: "Understanding Personal Loans",
          description: "Learn about the basics of personal loans and how they can help you achieve your financial goals.",
          url: "#",
          imageUrl: "/placeholder.svg?height=200&width=300&text=Personal+Loans",
          date: new Date().toLocaleDateString(),
        },
        {
          title: "The Importance of Credit Scores",
          description: "Discover why your credit score matters and how it affects your ability to secure loans and financial products.",
          url: "#",
          imageUrl: "/placeholder.svg?height=200&width=300&text=Credit+Scores",
          date: new Date().toLocaleDateString(),
        },
        {
          title: "Navigating Business Loans",
          description: "A comprehensive guide to understanding and applying for business loans to grow your enterprise.",
          url: "#",
          imageUrl: "/placeholder.svg?height=200&width=300&text=Business+Loans",
          date: new Date().toLocaleDateString(),
        },
        {
          title: "Home Loans: What You Need to Know",
          description: "Everything you need to know about home loans, from application to approval and beyond.",
          url: "#",
          imageUrl: "/placeholder.svg?height=200&width=300&text=Home+Loans",
          date: new Date().toLocaleDateString(),
        },
        {
          title: "The Future of Digital Banking",
          description: "Explore the latest trends and innovations in digital banking and how they're shaping the financial landscape.",
          url: "#",
          imageUrl: "/placeholder.svg?height=200&width=300&text=Digital+Banking",
          date: new Date().toLocaleDateString(),
        },
        {
          title: "Financial Planning for Beginners",
          description: "Start your journey to financial wellness with these essential tips for beginners.",
          url: "#",
          imageUrl: "/placeholder.svg?height=200&width=300&text=Financial+Planning",
          date: new Date().toLocaleDateString(),
        }
      ])
    } finally {
      setIsNewsLoading(false)
    }
  }

  useEffect(() => {
    fetchNews()
  }, [])

  // Autoplay for slides
  useEffect(() => {
    const interval = setInterval(() => {
      setSliderIndex((prevIndex) => (prevIndex + 1) % slides.length)
    }, 8000)

    return () => clearInterval(interval)
  }, [slides.length])

  const adjustValue = (setter: React.Dispatch<React.SetStateAction<number>>, value: number, step: number, min: number, max: number) => {
    setter((prevValue) => {
      const newValue = prevValue + step * value
      return Math.min(Math.max(newValue, min), max)
    })
  }

  return (
    <div className="font-sans bg-gray-50">
      {/* Topbar */}
      <div className="bg-emerald-700 text-white py-2">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 mb-2 sm:mb-0">
              <a href="mailto:reachus@nandafincap.com" className="flex items-center text-sm">
                <Mail className="w-4 h-4 mr-1" />
                <span className="hidden sm:inline">reachus@nandafincap.com</span>
                <span className="sm:hidden">Email Us</span>
              </a>
              <a href="tel:+917231981888" className="flex items-center text-sm">
                <Phone className="w-4 h-4 mr-1" />
                <span className="hidden sm:inline">+91 7231981888</span>
                <span className="sm:hidden">Call Us</span>
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a href="https://www.facebook.com/people/Nanda-Fincap/61563749070313/" className="text-white hover:text-yellow-300" aria-label="Facebook">
                <Facebook className="w-4 h-4" />
              </a>
              <a href="#" className="text-white hover:text-yellow-300" aria-label="Twitter">
                <Twitter className="w-4 h-4" />
              </a>
              <a href="#" className="text-white hover:text-yellow-300" aria-label="Instagram">
                <Instagram className="w-4 h-4" />
              </a>
              <a href="#" className="text-white hover:text-yellow-300" aria-label="LinkedIn">
                <Linkedin className="w-4 h-4" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Header */}
      <header className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-4 py-4">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="flex items-center mb-4 lg:mb-0">
              <img 
                src="https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/nanda-fincap-logo.svg?height=50&width=150"
                alt="Nanda Fincap Logo"
                className="h-12"
              />
            </div>
            <nav className="hidden lg:flex">
              <ul className="flex space-x-6">
                <li><ScrollLink to="home" smooth={true} duration={500} offset={-70} className="text-emerald-700 font-semibold cursor-pointer hover:text-emerald-500 transition duration-300">Home</ScrollLink></li>
                <li><ScrollLink to="about" smooth={true} duration={500} offset={-70} className="text-gray-600 cursor-pointer hover:text-emerald-500 transition duration-300">About Us</ScrollLink></li>
                <li><ScrollLink to="loan-products" smooth={true} duration={500} offset={-70} className="text-gray-600 cursor-pointer hover:text-emerald-500 transition duration-300">Loan Products</ScrollLink></li>
                <li><ScrollLink to="calculator" smooth={true} duration={500} offset={-70} className="text-gray-600 cursor-pointer hover:text-emerald-500 transition duration-300">EMI Calculator</ScrollLink></li>
                <li><ScrollLink to="careers" smooth={true} duration={500} offset={-70} className="text-gray-600 cursor-pointer hover:text-emerald-500 transition duration-300">Careers</ScrollLink></li>
                <li><ScrollLink to="blogs" smooth={true} duration={500} offset={-70} className="text-gray-600 cursor-pointer hover:text-emerald-500 transition duration-300">Blogs</ScrollLink></li>
              </ul>
            </nav>
            <div className="hidden lg:flex space-x-2">
              <ScrollLink to="apply" smooth={true} duration={500} offset={-70}>
                <button className="bg-yellow-400 text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-500 transition duration-300">Apply Now</button>
              </ScrollLink>
              <button onClick={handleCall} className="bg-emerald-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-emerald-700 transition duration-300 flex items-center">
                <Phone className="w-4 h-4 mr-2" />
                Call Us
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="pb-20 lg:pb-0">
        {/* Hero Slider */}
        <Element name="home" className="element">
          <section id="home" className="relative bg-gray-100">
            <div className="w-full h-[300px] md:h-[450px] lg:h-[600px] overflow-hidden">
              <picture>
                <source media="(min-width: 768px)" srcSet={slides[sliderIndex].desktop} />
                <img 
                  src={slides[sliderIndex].mobile}
                  alt={`Slide ${sliderIndex + 1}`} 
                  className="w-full h-full object-cover"
                />
              </picture>
              <button 
                onClick={() => setSliderIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length)}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full shadow-md hover:bg-opacity-75 transition duration-300"
                aria-label="Previous slide"
              >
                <ChevronLeft className="w-6 h-6 text-gray-800" />
              </button>
              <button 
                onClick={() => setSliderIndex((prevIndex) => (prevIndex + 1) % slides.length)}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full shadow-md hover:bg-opacity-75 transition duration-300"
                aria-label="Next slide"
              >
                <ChevronRight className="w-6 h-6 text-gray-800" />
              </button>
              <div className="absolute inset-x-0 bottom-0 flex justify-center space-x-2 pb-4">
                {slides.map((_, index) => (
                  <button
                    key={index}
                    className={`w-3 h-3 rounded-full ${index === sliderIndex ? 'bg-yellow-400' : 'bg-white bg-opacity-50'}`}
                    onClick={() => setSliderIndex(index)}
                  />
                ))}
              </div>
            </div>
          </section>
        </Element>

        {/* About Section */}
        <Element name="about" className="element">
          <section id="about" className="py-20 bg-white">
            <div className="container mx-auto px-4">
              <div className="flex flex-col lg:flex-row items-center">
                <div className="lg:w-1/2 lg:pr-12 mb-12 lg:mb-0">
                  <h2 className="text-4xl font-bold text-emerald-700 mb-6">Empowering Your Financial Future</h2>
                  <p className="text-gray-600 mb-8 text-lg">
                    At Nanda Fincap, we're more than just a financial institution. We're your partners in progress, committed to revolutionizing how individuals and businesses access and manage their finances.
                  </p>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="flex items-start">
                      <div className="bg-emerald-100 rounded-full p-3 mr-4">
                        <Shield className="w-6 h-6 text-emerald-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-lg mb-2">Secure Transactions</h3>
                        <p className="text-gray-600">Your financial security is our top priority.</p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="bg-emerald-100 rounded-full p-3 mr-4">
                        <Users className="w-6 h-6 text-emerald-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-lg mb-2">Personalized Approach</h3>
                        <p className="text-gray-600">Tailored solutions for your unique needs.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2">
                  <div className="bg-emerald-50 rounded-lg p-8 shadow-lg">
                    <h3 className="text-2xl font-bold text-emerald-700 mb-6">Our Impact</h3>
                    <div className="grid grid-cols-2 gap-8">
                      <div className="text-center">
                        <p className="text-4xl font-bold text-emerald-600 mb-2">10K+</p>
                        <p className="text-gray-600">Satisfied Customers</p>
                      </div>
                      <div className="text-center">
                        <p className="text-4xl font-bold text-emerald-600 mb-2">₹500Cr+</p>
                        <p className="text-gray-600">Loans Disbursed</p>
                      </div>
                      <div className="text-center">
                        <p className="text-4xl font-bold text-emerald-600 mb-2">15+</p>
                        <p className="text-gray-600">Financial Products</p>
                      </div>
                      <div className="text-center">
                        <p className="text-4xl font-bold text-emerald-600 mb-2">24/7</p>
                        <p className="text-gray-600">Customer Support</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-16">
                <h3 className="text-2xl font-bold text-center text-emerald-700 mb-8">Trusted by Leading Institutions</h3>
                <div className="flex justify-center items-center flex-wrap gap-8">
                  <img src="https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/Google-reviews.webp?height=50&width=200" alt="Google Rating" className="h-12" />
                </div>
              </div>
            </div>
          </section>
        </Element>

        {/* Banking and Loan Products */}
        <Element name="loan-products" className="element">
          <section id="loan-products" className="py-16 bg-emerald-50">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center text-emerald-700 mb-8">Comprehensive Financial Solutions</h2>
              <p className="text-center text-gray-600 mb-12">Explore our wide range of customized products designed to fuel your financial growth</p>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                {[
                  { icon: <CreditCard className="w-10 h-10" />, title: "Free Credit Score" },
                  { icon: <Users className="w-10 h-10" />, title: "Personal Loan" },
                  { icon: <Building className="w-10 h-10" />, title: "Business Loan" },
                  { icon: <Home className="w-10 h-10" />, title: "Home Loan" },
                  { icon: <Landmark className="w-10 h-10" />, title: "Property Loan" },
                  { icon: <Clock className="w-10 h-10" />, title: "Short Term Loan" },
                  { icon: <Briefcase className="w-10 h-10" />, title: "2 Wheeler Loan" },
                  { icon: <Car className="w-10 h-10" />, title: "Car Loan" },
                  { icon: <Stethoscope className="w-10 h-10" />, title: "Medical Loan" },
                  { icon: <ShieldCheck className="w-10 h-10" />, title: "Gold Loan" },
                  { icon: <GraduationCap className="w-10 h-10" />, title: "Education Loan" },
                  { icon: <Landmark className="w-10 h-10" />, title: "Government Schemes" },
                  { icon: <Tractor className="w-10 h-10" />, title: "Agriculture Loan" },
                  { icon: <Plane className="w-10 h-10" />, title: "Travel Loan" },
                  { icon: <Leaf className="w-10 h-10" />, title: "Green Energy Loan" },
                  { icon: <Smartphone className="w-10 h-10" />, title: "Gadget Loan" },
                  { icon: <ShoppingBag className="w-10 h-10" />, title: "Consumer Durable Loan" },
                  { icon: <Building className="w-10 h-10" />, title: "Commercial Property Loan" },
                  { icon: <CreditCardIcon className="w-10 h-10" />, title: "Credit Card" },
                  { icon: <Tractor className="w-10 h-10" />, title: "Kisan Credit Card" },
                  { icon: <Stethoscope className="w-10 h-10" />, title: "Medical Equipment Loan" },
                  { icon: <Cog className="w-10 h-10" />, title: "Machinery Loan" },
                  { icon: <Car className="w-10 h-10" />, title: "Used Car Loan" },
                  { icon: <BarChart3 className="w-10 h-10" />, title: "Loan Against Mutual Funds" },
                ].map((product, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg transition duration-300">
                    <div className="w-16 h-16 mx-auto mb-4 bg-emerald-100 rounded-full flex items-center justify-center text-emerald-600">
                      {product.icon}
                    </div>
                    <p className="text-sm font-medium text-gray-800">{product.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Element>

        {/* Partnered Banks and NBFCs */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center text-emerald-700 mb-8">Our Trusted Partners</h2>
            <p className="text-center text-gray-600 mb-12">We've joined forces with India's leading financial institutions to bring you the best services</p>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8">
              {bankLogos.map((bank, index) => (
                <div key={index} className="flex items-center justify-center p-4 bg-gray-50 rounded-lg hover:shadow-md transition duration-300">
                  <img 
                    src={bank.logo} 
                    alt={`${bank.name} logo`} 
                    className="max-h-12 max-w-full object-contain"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* EMI Calculator */}
        <Element name="calculator" className="element">
          <section id="calculator" className="py-16 bg-gradient-to-r from-emerald-600 to-emerald-800 text-white">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center mb-8">EMI Calculator</h2>
              <div className="bg-white text-gray-800 p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <h3 className="text-xl font-semibold mb-6 text-emerald-700">Loan Details</h3>
                    <div className="space-y-6">
                      <div>
                        <label htmlFor="loanAmount" className="block mb-2 text-sm font-medium text-gray-700">Loan Amount (₹)</label>
                        <div className="flex items-center">
                          <button
                            onClick={() => adjustValue(setLoanAmount, -1, 10000, 10000, 5000000)}
                            className="bg-emerald-100 text-emerald-700 p-2 rounded-l-md hover:bg-emerald-200 transition-colors"
                          >
                            <Minus className="w-4 h-4" />
                          </button>
                          <input
                            id="loanAmount"
                            type="number"
                            value={loanAmount}
                            onChange={(e) => setLoanAmount(Number(e.target.value))}
                            className="w-full p-2 text-center border-y border-emerald-200 outline-none"
                            min="10000"
                            max="5000000"
                          />
                          <button
                            onClick={() => adjustValue(setLoanAmount, 1, 10000, 10000,5000000)}
                            className="bg-emerald-100 text-emerald-700 p-2 rounded-r-md hover:bg-emerald-200 transition-colors"
                          >
                            <Plus className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="interestRate" className="block mb-2 text-sm font-medium text-gray-700">Interest Rate (%)</label>
                        <div className="flex items-center">
                          <button
                            onClick={() => adjustValue(setInterestRate, -1, 0.1, 1, 30)}
                            className="bg-emerald-100 text-emerald-700 p-2 rounded-l-md hover:bg-emerald-200 transition-colors"
                          >
                            <Minus className="w-4 h-4" />
                          </button>
                          <input
                            id="interestRate"
                            type="number"
                            value={interestRate}
                            onChange={(e) => setInterestRate(Number(e.target.value))}
                            className="w-full p-2 text-center border-y border-emerald-200 outline-none"
                            min="1"
                            max="30"
                            step="0.1"
                          />
                          <button
                            onClick={() => adjustValue(setInterestRate, 1, 0.1, 1, 30)}
                            className="bg-emerald-100 text-emerald-700 p-2 rounded-r-md hover:bg-emerald-200 transition-colors"
                          >
                            <Plus className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="loanTerm" className="block mb-2 text-sm font-medium text-gray-700">Loan Term (months)</label>
                        <div className="flex items-center">
                          <button
                            onClick={() => adjustValue(setLoanTerm, -1, 1, 1, 360)}
                            className="bg-emerald-100 text-emerald-700 p-2 rounded-l-md hover:bg-emerald-200 transition-colors"
                          >
                            <Minus className="w-4 h-4" />
                          </button>
                          <input
                            id="loanTerm"
                            type="number"
                            value={loanTerm}
                            onChange={(e) => setLoanTerm(Number(e.target.value))}
                            className="w-full p-2 text-center border-y border-emerald-200 outline-none"
                            min="1"
                            max="360"
                          />
                          <button
                            onClick={() => adjustValue(setLoanTerm, 1, 1, 1, 360)}
                            className="bg-emerald-100 text-emerald-700 p-2 rounded-r-md hover:bg-emerald-200 transition-colors"
                          >
                            <Plus className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-6 text-emerald-700">EMI Details</h3>
                    <div className="space-y-6">
                      <div className="bg-emerald-100 p-6 rounded-lg text-center">
                        <p className="text-sm font-medium text-emerald-800 mb-2">Monthly EMI</p>
                        <p className="text-4xl font-bold text-emerald-600">₹ {emi.toFixed(2)}</p>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="bg-gray-100 p-4 rounded-lg">
                          <p className="text-sm font-medium text-gray-600 mb-1">Total Interest</p>
                          <p className="text-lg font-semibold text-emerald-700">₹ {totalInterest.toFixed(2)}</p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-lg">
                          <p className="text-sm font-medium text-gray-600 mb-1">Total Amount</p>
                          <p className="text-lg font-semibold text-emerald-700">₹ {totalAmount.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>

        {/* Apply for a loan */}
        <Element name="apply" className="element">
          <section id="apply" className="py-16 bg-gradient-to-br from-emerald-600 to-emerald-800">
            <div className="container mx-auto px-4">
              <h2 className="text-4xl font-bold text-center text-white mb-12">Empower Your Financial Journey with Nanda Fincap</h2>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="bg-white/10 text-white rounded-lg p-8">
                  <h3 className="text-2xl font-semibold mb-6">Why Choose Nanda Fincap?</h3>
                  <p className="text-yellow-300 mb-8">Experience financial empowerment with our tailored solutions</p>
                  <ul className="space-y-4">
                    {[
                      { icon: <Percent className="w-5 h-5" />, text: "Competitive interest rates from 8.75% to 35%" },
                      { icon: <IndianRupee className="w-5 h-5" />, text: "Flexible loan amounts from ₹50,000 to ₹50 Lakh" },
                      { icon: <Clock className="w-5 h-5" />, text: "Customizable repayment tenure of 12 to 72 months" },
                      { icon: <Shield className="w-5 h-5" />, text: "Transparent process with no hidden charges" },
                      { icon: <CheckCircle className="w-5 h-5" />, text: "Swift approval and disbursal process" },
                      { icon: <FileText className="w-5 h-5" />, text: "Minimal documentation for hassle-free experience" },
                      { icon: <Users className="w-5 h-5" />, text: "Dedicated support team for personalized assistance" },
                      { icon: <Briefcase className="w-5 h-5" />, text: "Tailored solutions for salaried and self-employed" },
                      { icon: <Smartphone className="w-5 h-5" />, text: "User-friendly online application process" },
                      { icon: <Award className="w-5 h-5" />, text: "Trusted by thousands of satisfied customers" },
                      { icon: <Zap className="w-5 h-5" />, text: "Instant eligibility check for quick decisions" },
                    ].map((item, index) => (
                      <li key={index} className="flex items-center">
                        <div className="bg-yellow-400 text-emerald-800 p-2 rounded-full mr-3">{item.icon}</div>
                        <span>{item.text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="bg-white rounded-lg p-8">
                  <h3 className="text-2xl font-semibold mb-2 text-emerald-700">Start Your Financial Journey</h3>
                  <p className="text-gray-600 mb-6">Fill out the form below to take the first step towards financial freedom</p>
                  <form onSubmit={handleContactSubmit} className="space-y-4">
                    <div className="space-y-4">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                        <input
                          id="name"
                          type="text"
                          placeholder="John Doe"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                        <input
                          id="email"
                          type="email"
                          placeholder="john@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                        <input
                          id="phone"
                          type="tel"
                          placeholder="+91 1234567890"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="loanType" className="block text-sm font-medium text-gray-700 mb-1">Loan Type</label>
                        <select
                          id="loanType"
                          value={loanType}
                          onChange={(e) => setLoanType(e.target.value)}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        >
                          <option value="">Select Loan Type</option>
                          <option value="Personal Loan">Personal Loan</option>
                          <option value="Business Loan">Business Loan</option>
                          <option value="Home Loan">Home Loan</option>
                          <option value="Car Loan">Car Loan</option>
                          <option value="Education Loan">Education Loan</option>
                          <option value="Gold Loan">Gold Loan</option>
                          <option value="Property Loan">Property Loan</option>
                          <option value="Short Term Loan">Short Term Loan</option>
                          <option value="2 Wheeler Loan">2 Wheeler Loan</option>
                          <option value="Medical Loan">Medical Loan</option>
                          <option value="Agriculture Loan">Agriculture Loan</option>
                          <option value="Travel Loan">Travel Loan</option>
                          <option value="Green Energy Loan">Green Energy Loan</option>
                          <option value="Gadget Loan">Gadget Loan</option>
                          <option value="Consumer Durable Loan">Consumer Durable Loan</option>
                          <option value="Commercial Property Loan">Commercial Property Loan</option>
                          <option value="Medical Equipment Loan">Medical Equipment Loan</option>
                          <option value="Machinery Loan">Machinery Loan</option>
                          <option value="Used Car Loan">Used Car Loan</option>
                          <option value="Loan Against Mutual Funds">Loan Against Mutual Funds</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="requiredLoan" className="block text-sm font-medium text-gray-700 mb-1">Required Loan Amount</label>
                        <input
                          id="requiredLoan"
                          type="text"
                          placeholder="₹ 500,000"
                          value={requiredLoan}
                          onChange={(e) => setRequiredLoan(e.target.value)}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Additional Information</label>
                        <textarea
                          id="message"
                          placeholder="Any specific requirements or questions?"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          rows={4}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-yellow-400 text-gray-900 py-2 px-4 rounded-md hover:bg-yellow-500 transition duration-300 flex items-center justify-center font-semibold"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Loader2 className="animate-spin mr-2 h-5 w-5" />
                          Processing...
                        </>
                      ) : (
                        'Apply'
                      )}
                    </button>
                  </form>
                  {formSubmitted && (
                    <div className="mt-4 p-4 bg-emerald-100 text-emerald-700 rounded-md flex items-center">
                      <CheckCircle className="w-5 h-5 mr-2" />
                      <span>Thank you for choosing Nanda Fincap. We'll be in touch soon!</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </Element>

        {/* Our Team */}
        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center text-emerald-700 mb-8">Meet the Nanda Fincap Team</h2>
            <p className="text-center text-gray-600 mb-12">The experts behind your financial success</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {teamMembers.map((member, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg transition duration-300">
                  <img 
                    src={member.image} 
                    alt={member.name} 
                    className="w-32 h-32 rounded-full mx-auto mb-4 object-cover border-4 border-emerald-200"
                    loading="lazy"
                  />
                  <h3 className="text-xl font-semibold mb-2 text-emerald-700">{member.name}</h3>
                  {/* <p className="text-gray-600 mb-4">{member.role}</p> */}
                  <button 
                    onClick={() => openModal(member)} 
                    className="bg-emerald-600 text-white px-4 py-2 rounded-full font-semibold hover:bg-emerald-700 transition duration-300"
                  >
                    Learn More
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Careers */}
        <Element name="careers" className="element">
          <section id="careers" className="py-16 bg-white">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center text-emerald-700 mb-8">Join the Nanda Fincap Family</h2>
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div className="bg-emerald-50 p-6 rounded-lg h-full flex flex-col justify-between">
                    <div>
                      <h3 className="text-2xl font-semibold mb-4 text-emerald-700">Employee Testimonials</h3>
                      <div className="space-y-4">
                        {employeeTestimonials.map((testimonial, index) => (
                          <div key={index} className="bg-white p-4 rounded-lg shadow">
                            <p className="italic mb-2 text-gray-600">"{testimonial.quote}"</p>
                            <p className="font-semibold text-emerald-600">{testimonial.name}, {testimonial.role}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-6">
                      <p className="text-emerald-700 font-semibold">Be part of our success story and grow with us!</p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                  <div className="bg-emerald-600 text-white p-6 rounded-lg h-full flex flex-col justify-between">
                    <div>
                      <h3 className="text-2xl font-semibold mb-4">Shape the Future of Finance</h3>
                      <p className="text-lg mb-4">At Nanda Fincap, we're always on the lookout for passionate individuals who want to make a difference in the world of finance. If you're driven, innovative, and ready to empower others, we want to hear from you!</p>
                      <p className="text-lg mb-6">To explore opportunities, send your resume and cover letter to:</p>
                    </div>
                    <div>
                      <a 
                        href="mailto:careers@nandafincap.com" 
                        className="inline-flex items-center justify-center w-full bg-yellow-400 text-gray-900 p-3 rounded-full hover:bg-yellow-500 transition duration-300 font-semibold"
                      >
                        <Mail className="w-5 h-5 mr-2" />
                        Apply via Email
                      </a>
                      <p className="text-sm mt-4 text-yellow-200">
                        Please include the position you're interested in within the subject line of your email.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>

        {/* Blogs */}
        <Element name="blogs" className="element">
          <section id="blogs" className="py-16 bg-gray-50">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center text-emerald-700 mb-8">Financial Insights & News</h2>
              {isNewsLoading ? (
                <div className="flex justify-center items-center h-64">
                  <Loader2 className="w-12 h-12 animate-spin text-emerald-600" />
                </div>
              ) : newsError ? (
                <div className="text-center text-red-600 mb-8">
                  <p>{newsError}</p>
                  <button 
                    onClick={fetchNews} 
                    className="mt-4 bg-emerald-600 text-white px-4 py-2 rounded-full hover:bg-emerald-700 transition duration-300"
                  >
                    Refresh News
                  </button>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {blogPosts.map((post, index) => (
                    <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300">
                      <img 
                        src={post.imageUrl || "/placeholder.svg?height=200&width=300&text=Nanda+Fincap+News"} 
                        alt={post.title} 
                        className="w-full h-48 object-cover"
                        loading="lazy"
                      />
                      <div className="p-6">
                        <h3 className="text-xl font-semibold mb-2 text-emerald-700">{post.title}</h3>
                        <p className="text-gray-600 mb-4">{post.description}</p>
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-gray-500">{post.date}</span>
                          <a href={post.url} target="_blank" rel="noopener noreferrer" className="text-yellow-500 hover:text-yellow-600 font-semibold">Read More</a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        </Element>
      </main>

      {/* Footer */}
      <footer className="bg-emerald-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
              <img src="https://nanda-fincap-assets.gumlet.io/wp-content/uploads/2024/05/nanda-fincap-logo.svg?height=50&width=150" alt="Nanda Fincap Logo" className="h-12 mb-4" />
              <p className="text-sm">Nanda Fincap: Your trusted partner for all financial needs. We provide customized solutions to help you achieve your financial goals and secure your future.</p>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><ScrollLink to="home" smooth={true} duration={500} offset={-70} className="hover:text-yellow-300 cursor-pointer transition duration-300">Home</ScrollLink></li>
                <li><ScrollLink to="about" smooth={true} duration={500} offset={-70} className="hover:text-yellow-300 cursor-pointer transition duration-300">About Us</ScrollLink></li>
                <li><ScrollLink to="loan-products" smooth={true} duration={500} offset={-70} className="hover:text-yellow-300 cursor-pointer transition duration-300">Loan Products</ScrollLink></li>
                <li><ScrollLink to="calculator" smooth={true} duration={500} offset={-70} className="hover:text-yellow-300 cursor-pointer transition duration-300">EMI Calculator</ScrollLink></li>
                <li><ScrollLink to="careers" smooth={true} duration={500} offset={-70} className="hover:text-yellow-300 cursor-pointer transition duration-300">Careers</ScrollLink></li>
                <li><ScrollLink to="blogs" smooth={true} duration={500} offset={-70} className="hover:text-yellow-300 cursor-pointer transition duration-300">Blogs</ScrollLink></li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <Phone className="w-4 h-4 mr-2 text-yellow-400" />
                  +91 7231981888
                </li>
                <li className="flex items-center">
                  <Phone className="w-4 h-4 mr-2 text-yellow-400" />
                  +91 7231969888
                </li>
                <li className="flex items-center">
                  <Mail className="w-4 h-4 mr-2 text-yellow-400" />
                  reachus@nandafincap.com
                </li>
                <li className="flex items-start">
                  <MapPin className="w-4 h-4 mr-2 mt-1 text-yellow-400" />
                  <span>Kota, Jaipur, Mumbai</span>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 px-4">
              <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
              <div className="flex space-x-4">
                <a href="https://www.facebook.com/people/Nanda-Fincap/61563749070313/" className="hover:text-yellow-300 transition duration-300">
                  <Facebook className="w-6 h-6" />
                </a>
                <a href="#" className="hover:text-yellow-300 transition duration-300">
                  <Twitter className="w-6 h-6" />
                </a>
                <a href="#" className="hover:text-yellow-300 transition duration-300">
                  <Instagram className="w-6 h-6" />
                </a>
                <a href="#" className="hover:text-yellow-300 transition duration-300">
                  <Linkedin className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-emerald-800 text-center">
            <p className="text-sm">&copy; {new Date().getFullYear()} Nanda Fincap Pvt Ltd. All rights reserved.</p>
          </div>
        </div>
      </footer>

      {/* Scroll to Top Button */}
      <button
        onClick={scrollToTop}
        className="fixed bottom-20 right-4 bg-yellow-400 text-gray-900 p-2 rounded-full shadow-md hover:bg-yellow-500 transition duration-300 lg:bottom-4"
        aria-label="Scroll to top"
      >
        <ChevronUp className="w-6 h-6" />
      </button>

      {/* Team Member Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full">
            <button onClick={closeModal} className="float-right text-gray-600 hover:text-gray-800">
              <X className="w-6 h-6" />
            </button>
            <img 
              src={modalContent.image} 
              alt={modalContent.name} 
              className="w-32 h-32 rounded-full mx-auto mb-4 object-cover border-4 border-emerald-200"
            />
            <h3 className="text-2xl font-semibold mb-2 text-center text-emerald-700">{modalContent.name}</h3>
            {/* <p className="text-yellow-500 mb-4 text-center">{modalContent.role}</p> */}
            <p className="text-gray-700">{modalContent.description}</p>
          </div>
        </div>
      )}

      {/* Mobile Navigation */}
      <nav className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
        <div className="flex justify-around items-center py-2">
          <ScrollLink to="home" smooth={true} duration={500} offset={-70} className="flex flex-col items-center text-emerald-600 hover:text-emerald-700">
            <HomeIcon className="w-6 h-6" />
            <span className="text-xs">Home</span>
          </ScrollLink>
          <ScrollLink to="about" smooth={true} duration={500} offset={-70} className="flex flex-col items-center text-emerald-600 hover:text-emerald-700">
            <Info className="w-6 h-6" />
            <span className="text-xs">About</span>
          </ScrollLink>
          <ScrollLink to="loan-products" smooth={true} duration={500} offset={-70} className="flex flex-col items-center text-emerald-600 hover:text-emerald-700">
            <IndianRupee className="w-6 h-6" />
            <span className="text-xs">Products</span>
          </ScrollLink>
          <ScrollLink to="apply" smooth={true} duration={500} offset={-70} className="flex flex-col items-center text-emerald-600 hover:text-emerald-700">
            <FileText className="w-6 h-6" />
            <span className="text-xs">Apply</span>
          </ScrollLink>
          <ScrollLink to="careers" smooth={true} duration={500} offset={-70} className="flex flex-col items-center text-emerald-600 hover:text-emerald-700">
            <User className="w-6 h-6" />
            <span className="text-xs">Careers</span>
          </ScrollLink>
        </div>
      </nav>
    </div>
  )
}